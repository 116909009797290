import React from "react"
import styled from "styled-components"
import { Section, SectionWrapper, Text, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"

import CompetitiveQuotes from "../../../assets/images/why-choose-verak/competitiveQuotes.svg"
import WASupport from "../../../assets/images/why-choose-verak/waSupport.svg"
import ExpertGuidance from "../../../assets/images/why-choose-verak/expertGuidance.svg"

export const InsuranceNeedsCard = ({desc, icon, fixedWidth=false}) => {
  let iconStyles = {borderRadius: "4px"}
  const getIcon = () => {
    switch (icon) {
      case "Instant Quotes": 
        return <CompetitiveQuotes className="icon" style={iconStyles} />
      case "Whatsapp Support": 
        return <WASupport className="icon" style={iconStyles} />
      case "Complete Guidance":
        return <ExpertGuidance className="icon" style={iconStyles} />
      default:
        return;
    }
  }
  return (
    <InsNeedsCard fixedWidth={fixedWidth}>
      <InsNeedsIconContainer>
        {getIcon()}
      </InsNeedsIconContainer>
      <Text fontSize="20px" lineHeight="36px" mfontSize="14px" mlineHeight="24px" fontWeight="bold">
        {desc}
      </Text>
    </InsNeedsCard>
  )
}

const WhyChooseVerak = ({
  handleGetQuoteClick = () => {},
  quoteCtaProps={},
  scrollableCards=false,
  showGetQuote = false,
  safetyPlan = false,
  style={},
  desktopStyles={background: "#F6F9FF"},
  mobileStyles={background: "#fff"},
  title="Verak gives you:"
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles} style={style}>
      <SectionWrapper>
        <Title fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="22px" style={{textAlign: "center"}} desktopStyles={{marginBottom: "60px"}} mobileStyles={{marginBottom: '40px'}}>
          {title}
        </Title>
        <InsNeedsContainer scrollableCards={scrollableCards}>
          <InsuranceNeedsCard 
            fixedWidth={scrollableCards} 
            icon="Whatsapp Support" 
            desc="Online and paperless buying process"
          />
          <InsuranceNeedsCard 
            fixedWidth={scrollableCards} 
            icon="Complete Guidance" 
            desc={safetyPlan ? "Expert guidance from purchase to support to claims" : "Expert guidance from purchase to claims"}
          />
          <InsuranceNeedsCard 
            fixedWidth={scrollableCards} 
            icon="Instant Quotes" 
            desc={safetyPlan ? "Best Prices for insurance and safety equipment" : "Competitive quotes from reputed insurers"}
          />
        </InsNeedsContainer>
        {showGetQuote ? (
          <>
            <OnDesktop><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
            <OnMobile><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "36px", padding: '10px 16px'}} onClick={handleGetQuoteClick} /></OnMobile>
          </>
        ) : null}
      </SectionWrapper>
    </Section>
  )
}

const InsNeedsCard = styled.article`
  position: relative;
  padding: 40px 58px;
  width: 410px;
  background: #FFFFFF;
  border: 0.5px solid rgba(139, 142, 209, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  text-align: center;
  @media (max-width: 768px) {
    width: ${p => p.fixedWidth ? "184px" : "min(240px, 100%)"};
    min-width: ${p => p.fixedWidth ? "184px" : "auto"};
    background: #F6F9FF;
    padding: 1rem;
    .icon {
      width: 84px;
    }
  }
`

const InsNeedsIconContainer = styled.div`
  border-radius: 4px;
`

export const InsNeedsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 100px;
  @media (max-width: 768px) {
    flex-direction: ${p => p.scrollableCards ? "row" : "column"};
    overflow: auto;
    align-items: center;
    margin-top: 70px;
  }
`

export default WhyChooseVerak